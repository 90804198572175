import { css } from 'emotion';

import {
  fontBody,
  fontHeading3,
  fontSubheading1,
  fontCaption1,
} from 'assets/css/fontStyles';

import {
  cardStyle,
  tabContentWrapper,
  notAvilableContent,
  divider,
} from '../../styles';

export { tabContentWrapper, notAvilableContent, divider };

const meddicFieldWithAI = css`
  border-radius: 4px;
  background: linear-gradient(90.78deg, #3884ff 0%, #c4e90f 100%);
  padding: 1px; /* Space for the border effect */
  border: none;
`;

const meddicFieldWithAIContent = css`
  border-radius: inherit; /* Match the border-radius */
  background: white; /* Content background */
  padding: 8px; /* Adjust padding as needed */
  border: none;
  width: 100%;
`;

const lastUpdatedGenAi = css`
  ${fontCaption1}
  color: var(--bu-gray-700);
`;

export const actionItemsTabWrapper = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-large);

  .action-items-card {
    ${cardStyle}
    display: flex;
    gap: var(--bu-control-gap-large);

    .content {
      display: flex;
      flex-direction: column;
      gap: var(--bu-control-gap-medium);

      .content-description {
        margin-right: 35px;

        .done-wrapper {
          width: 24px;
          height: 24px;
          border: 1px solid var(--bu-gray-600);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 24px;
          margin-right: 5px;

          &:disabled {
            cursor: not-allowed;
            background-color: var(--bu-gray-300);

            &.done {
              opacity: 0.5;
            }
          }

          &.done {
            background-color: var(--bu-green-500);
          }

          .done-icon {
            i {
              color: var(--bu-gray-400);
              font-size: 14px !important;
            }

            &.done {
              i {
                color: var(--bu-white);
              }
            }
          }
        }

        i {
          font-size: 18px;
        }
      }

      .content-actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--bu-control-gap-medium);
        margin-left: 25px;

        .last-updated-gen-ai {
          ${lastUpdatedGenAi}
        }

        &.margin-left-medium {
          margin-left: 40px;
        }

        button {
          font-size: 12px;
          margin: 0;
        }
      }

      .content-description {
        display: flex;
        align-items: center;
        gap: var(--bu-control-gap-medium);
      }

      .content-description,
      .content-actions {
        i.follow-up-email-icon {
          color: var(--bu-purple-500);
        }

        i.sales-process-icon {
          color: var(--bu-primary-500);
        }
      }
    }
  }
`;

export const followUpEmailModalWrapper = css`
  padding: 12px 0 0;

  .follow-up-email-modal-content {
    position: relative;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .follow-up-email-modal-content {
    padding: 12px 0;
  }

  .recipients,
  .subject {
    border-bottom: 1px solid var(--bu-gray-300);
    display: flex;
    align-items: center;
    min-height: 50px;

    .key {
      color: var(--bu-gray-700);
      margin-right: 8px;
    }

    button {
      i {
        font-size: 14px !important;
      }
    }
  }

  .subject {
    height: 32px;
  }

  .body {
    font-family: var(--bu-font-medium);
    padding: 12px 0 0;
  }

  .action-button {
    display: flex;
    justify-content: flex-end;
    gap: var(--bu-control-gap-large);

    button {
      margin: 0;
    }
  }

  .follow-up-email-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--bu-control-gap-medium);
    position: relative;
    padding: 12px 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 40px);
      height: 1px;
      margin-left: -20px;
      background-color: var(--bu-gray-400);
    }

    button:disabled {
      i {
        color: var(--bu-white) !important;
      }
    }

    .divider {
      ${divider}
      margin: 0 4px;
    }
  }
`;

export const salesProcessModalWrapper = css`
  .sales-process-modal-title {
    padding: 16px 0;
    ${fontHeading3}
    font-weight: 500;
  }

  .sales-process-modal-ai-infomartion {
    background-color: var(--bu-primary-200);
    border-radius: var(--bu-control-border-radius);
    padding: 12px 16px;
    margin: 12px 0;
    display: flex;
    gap: var(--bu-control-gap-medium);
    ${fontBody}

    i {
      font-size: 18px;
      color: var(--bu-primary-700);
      font-family: var(--bu-font-medium);
      font-weight: 600;
    }
  }

  .sales-process-modal-content {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    margin-bottom: 12px;
    position: relative;
    overflow-y: scroll;
    max-height: 700px;
    height: 100%;

    @media (max-height: 1080px) {
      max-height: 500px;
    }

    @media (max-height: 880px) {
      max-height: 300px;
    }

    @media (max-height: 600px) {
      max-height: 150px;
    }

    &.sales-process-modal-content-regenerating {
      overflow-y: hidden;
    }

    .right-side,
    .left-side {
      flex: 1;
    }

    /* Fixing the height of the note field on both sides */
    .left-side-fields,
    .right-side-fields {
      .meddic-field-note-wrapper {
        min-height: 126px;
      }
    }

    .right-side-fields {
      .meddic-field-with-ai {
        .meddic-field {
          ${meddicFieldWithAI}

          > * {
            ${meddicFieldWithAIContent}
          }
        }

        .meddic-field-money,
        .meddic-field-percentage {
          > * {
            padding: 0;
          }
        }

        .meddic-field-date {
          > * {
            padding: 0 8px;
          }
        }

        .meddic-field-note-wrapper {
          .max-length-info {
            margin-top: 3px;
          }

          .meddic-field-note {
            height: calc(100% - 23px);

            textarea {
              border: none;
            }

            > * {
              padding: 0;
              height: calc(100% - 23px);
            }
          }
        }
      }
    }

    .middle-arrow {
      flex: 0.2;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      align-self: center;
      color: var(--bu-color-call-light-gray);
      font-size: 20px;
    }

    .left-side-title,
    .right-side-title {
      text-transform: uppercase;
      font-size: 12px;
      font-family: var(--bu-font-semi-bold);
      font-weight: 600;
    }

    .last-updated-gen-ai {
      ${lastUpdatedGenAi}
    }

    .last-updated-gen-ai,
    .last-updated-gen-ai-placeholder {
      height: 16px;
    }
  }

  .sales-process-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--bu-control-gap-medium);
    padding: 12px 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 40px);
      height: 1px;
      margin-left: -20px;
      background-color: var(--bu-gray-400);
    }

    button:disabled {
      i {
        color: var(--bu-white) !important;
      }
    }

    .divider {
      ${divider}
      margin: 0 4px;
    }
  }
`;

export const salesProcessModalTitle = css`
  display: flex;
  align-items: center;
  font-family: var(--bu-font-semi-bold);
  font-size: 16px;
  font-weight: 600;
`;

export const followUpEmailModalTitle = css`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--bu-control-gap-small);
  padding-top: 4px;

  div {
    display: flex;
    align-items: center;
  }

  i {
    margin-left: 4px;
  }

  .last-updated-gen-ai {
    ${lastUpdatedGenAi}
  }
`;
