import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { useSelector } from 'react-redux';
import { getCallsActiveTab } from 'selectors/calls';
import { TABLE_ID } from 'common/constants';
import Table from 'components/UI/TableConfig/Table';

import * as styles from './styles';
import { useQuery } from '@tanstack/react-query';
import { Call, getCallsList } from 'api/Calls';
import { getSubmissionFilters } from 'selectors/settings';
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader';
import { useEffect, useState } from 'react';
import { getColumns } from './utils';
import { getFiltersForAPI } from 'selectors';
import { IReduxState } from 'reducers/types';
import { useHistory, useLocation } from 'react-router-dom';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';

interface Props {
  upcoming: boolean;
}

export const CallsListing = ({ upcoming }: Props) => {
  const activeTab = useSelector(getCallsActiveTab);
  const { loaded: filtersLoaded } = useSelector(getSubmissionFilters);
  const history = useHistory();
  const location = useLocation();

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchText, setSearchText] = useState('');
  const [setByUrl, setSetByUrl] = useState(false);
  const filters = useSelector((state: IReduxState) =>
    getFiltersForAPI(state, activeTab.filtersTab)
  );

  const payload = {
    ...filters,
    page_number: pageNumber - 1,
    page_size: pageSize,
    time_min: upcoming ? new Date() : null,
    time_max: upcoming ? null : new Date(),
    sort_criterion: upcoming ? 'start' : '-start',
    search: searchText.trim() || undefined,
  };

  const key = upcoming ? 'upcoming_calls' : 'completed_calls';
  const { data: callsData, isFetching: callsLoading } = useQuery({
    queryKey: [key, { ...payload, time_min: null, time_max: null }],
    retry: false,
    queryFn: ({ signal }) => getCallsList(payload, signal),
    enabled: filtersLoaded && setByUrl,
  });

  // Applying search and page number from url
  useEffect(() => {
    if (!setByUrl) {
      const params = new URLSearchParams(location.search);
      setSearchText(params.get('search') || '');
      setPageNumber(Number(params.get('page_number')) || 1);
      setSetByUrl(true);
    }
  }, [location.search]);

  // Storing search and page number in url
  useEffect(() => {
    const searchStr = searchText ? `&search=${searchText}` : '';
    history.push(`${location.pathname}?page_number=${pageNumber}${searchStr}`);
  }, [pageNumber, searchText]);

  // Resetting page number when filters change
  useEffect(() => {
    setPageNumber(1);
  }, [JSON.stringify(filters)]);

  if (!filtersLoaded) {
    return (
      <div className={styles.loader}>
        <Loader active inline="centered" />
      </div>
    );
  }

  const onPaginationChange = (pageNumber: number, pageSize: number) => {
    setPageNumber(pageNumber);
    setPageSize(pageSize);
  };

  const onSearchChange = (searchText: string) => {
    setSearchText(searchText);
    setPageNumber(1);
    history.push(
      `${location.pathname}?page_number=${pageNumber}&search=${searchText}`
    );
  };

  const columns = getColumns(upcoming);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filters}>
        <OpenFiltersPanel tab={activeTab.filtersTab} />
      </div>

      <div className={styles.callsListing}>
        <Table
          tableId={TABLE_ID.CALLS}
          title={upcoming ? 'Calls' : 'Completed Calls'}
          data={callsData?.data?.calls as Call[]}
          columns={columns}
          loading={callsLoading}
          rowsPerPage={pageSize}
          currentPage={pageNumber}
          totalCount={callsData?.data?.total_count}
          onPaginationChange={onPaginationChange}
          onSearchChange={onSearchChange}
          onSort={() => {}}
          searchPlaceholder="Search by name, opportunity or organizer"
          defaultSearchText={searchText}
          dataType="calls"
          fullscreen
          hidePaginationEnd
        />
      </div>
    </div>
  );
};

export default CallsListing;
