import React, { useMemo, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';

import { postShareCall } from 'api/CallInsights';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import BuDropdown, { BuDropdownItemContainer } from 'components/UI/BuDropdown';
import { getFeatureFlags } from 'selectors';

import * as styles from './styles';
import CallInvite from './CallInvite';
import { Attendee, CallInsightsResponse, Participant } from '../types';
import RoundedAvatarIcon from '../RoundedAvatarIcon';
import {
  formatDurationToFriendlyDurationString,
  getUniqueNameOrEmail,
} from '../helpers';

type Props = {
  attendees: Attendee[];
  participants: Participant[];
  speakerContactMap: CallInsightsResponse['speaker_contact_map'];
  title: string;
  duration: number;
  start: string;
  description: string;
  speakerPercentages: CallInsightsResponse['speaker_percentages'];
  isCallSummarized: boolean;
  hideInviteTooltip?: boolean;
  hideAttendeesTooltip?: boolean;
  callId?: string;
  shareId?: string;
};

const HeaderDetails: React.FC<Props> = ({
  attendees,
  participants,
  speakerContactMap,
  title,
  duration,
  start,
  description,
  speakerPercentages,
  isCallSummarized,
  hideInviteTooltip = false,
  hideAttendeesTooltip = false,
  callId = '',
  shareId: savedShareId = '',
}) => {
  const { company_vs_prospect_enabled } = useSelector((state) =>
    getFeatureFlags(state)
  );

  const [shareId, setShareId] = useState<string | undefined>(savedShareId);

  const organizer = useMemo(
    () => attendees.find((attendee) => attendee.organizer),
    [attendees]
  );

  const callStartDate = useMemo(
    () => moment(start).format('MMM D, YYYY [at] h.mma'),
    [start]
  );

  // If there is no participants, use attendees then
  const attendeesListForHeader = useMemo(
    () =>
      !!participants.length
        ? participants
        : attendees.map(
            (attendee) =>
              ({
                name: getUniqueNameOrEmail(attendee),
                speaker_percentage: 0,
                organizer: attendee.organizer,
                color: '',
                type: speakerContactMap
                  ?.find((contact) => contact.contact_email === attendee.email)
                  ?.contact_type?.toLowerCase() as Participant['type'],
              } as Participant)
          ),
    [attendees, participants]
  );

  const attendeesListForTooltip = useMemo(
    () =>
      attendeesListForHeader.slice().sort((a, b) => {
        if (a.organizer !== b.organizer) {
          return a.organizer ? -1 : 1;
        }

        return a.name.localeCompare(b.name);
      }),
    [attendeesListForHeader]
  );

  const callDateTooltip = (
    <div className={styles.callInviteTooltipWrapper}>
      <CallInvite
        attendees={attendees}
        title={title}
        description={description}
        callStartDate={callStartDate}
      />
    </div>
  );

  const durationTooltip = (
    <div className={styles.durationTooltipWrapper}>
      {duration > 0 ? (
        <div className="duration-total">
          Total Duration: {formatDurationToFriendlyDurationString(duration)}
          {!!speakerPercentages && (
            <span>
              Speaking time:{' '}
              {formatDurationToFriendlyDurationString(
                speakerPercentages.speak.duration
              )}
            </span>
          )}
        </div>
      ) : (
        // TODO: Will have this in the future
        // <div className="duration-splitted customer">
        //   <span className="duration-label">Customer:</span>

        //   <span className="duration-value">
        //     {formatDurationToFriendlyDurationString(
        //       speakerTypePercentages.customer.duration
        //     )}
        //   </span>

        //   <span className="duration-percentage">
        //     ({speakerTypePercentages.customer.percentage.toFixed(2)}%)
        //   </span>
        // </div>

        // <div className="duration-splitted company">
        //   <span className="duration-label">Company:</span>

        //   <span className="duration-value">
        //     {formatDurationToFriendlyDurationString(
        //       speakerTypePercentages.company.duration
        //     )}
        //   </span>

        //   <span className="duration-percentage">
        //     ({speakerTypePercentages.company.percentage.toFixed(2)}%)
        //   </span>
        // </div>
        <div className="duration-total">Call data not available</div>
      )}
    </div>
  );

  const attendeesTooltip = (
    <div className={styles.attendeesListTooltipWrapper}>
      {attendeesListForTooltip.map((attendee) => (
        <div className="attendee" key={attendee.name}>
          <RoundedAvatarIcon color={attendee.color} />

          <div className="attendee-info">
            {!!attendee.name && <span>{attendee.name}</span>}
            {attendee.organizer && <span>Organizer</span>}
            {!!attendee.type && company_vs_prospect_enabled && (
              <span className="attendee-type">
                {attendee.type === 'customer' ? 'Prospect' : attendee.type}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const { isFetching: isFetchingShareId, refetch: refetchPostShareCall } =
    useQuery({
      queryKey: ['post_share_call', callId],
      queryFn: () => postShareCall({ callId }),
      enabled: false,
    });

  const handleClickCopyLink = async (hideDropdownOptions: () => void) => {
    hideDropdownOptions();
    let _shareId: string | undefined = shareId;

    if (!_shareId) {
      const { data: refetchedShareId } = await refetchPostShareCall();
      _shareId = refetchedShareId;
    }

    setShareId(_shareId);

    if (_shareId) {
      navigator.clipboard
        .writeText(`${window.location.origin}/external/call/${_shareId}`)
        .then(() => {
          toast.success('Link copied to the clipboard.', {
            position: 'bottom-left',
          });
        })
        .catch(() => {
          toast.error('Failed to copy link to the clipboard.', {
            position: 'bottom-left',
          });
        });
    } else {
      toast.error('Failed to copy link to the clipboard.', {
        position: 'bottom-left',
      });
    }
  };

  return (
    <div className={styles.headerDetailsWrapper}>
      <div>
        <TooltipWrapper
          tooltip={isCallSummarized && !hideInviteTooltip && callDateTooltip}
          hoverable
          position="bottom left"
          popupClassName={styles.removeTooltipBefore}
        >
          <div className="detail">
            <BuIcon name={BoostUpIcons.Calendar} />
            <span>{callStartDate}</span>
          </div>
        </TooltipWrapper>

        <div className={classNames(styles.divider, 'minor')} />
        <TooltipWrapper
          tooltip={durationTooltip}
          hoverable
          position="bottom left"
          popupClassName={styles.removeTooltipBefore}
        >
          <div className="detail">
            <BuIcon name={BoostUpIcons.Clock} />
            <span>
              {formatDurationToFriendlyDurationString(duration, true)}
            </span>
          </div>
        </TooltipWrapper>

        <div className={classNames(styles.divider, 'minor')} />
        <TooltipWrapper
          tooltip={
            !hideAttendeesTooltip &&
            attendeesListForTooltip.length &&
            attendeesTooltip
          }
          hoverable
          position="bottom left"
          popupClassName={styles.removeTooltipBefore}
        >
          <div className="detail">
            <BuIcon name={BoostUpIcons.UserGroup} />

            <span>
              {attendeesListForHeader.length} Attendee
              {attendeesListForHeader.length > 1 ? 's' : ''}
            </span>
          </div>
        </TooltipWrapper>

        {!!organizer && (
          <>
            <div className={classNames(styles.divider, 'minor')} />

            <div className="detail organizer">
              <BuIcon name={BoostUpIcons.User} />
              <span>{getUniqueNameOrEmail(organizer)}</span>
            </div>
          </>
        )}
      </div>

      {!!callId && (
        <div>
          <BuDropdown
            className="share-dropdown"
            label={
              isFetchingShareId ? <Loader size="tiny" active inline /> : 'Share'
            }
            noDropdownIcon={isFetchingShareId ? true : undefined}
            disabled={isFetchingShareId}
          >
            {(hideDropdownOptions) => (
              <div>
                <BuDropdownItemContainer
                  className={styles.dropdownOption}
                  onClick={() => handleClickCopyLink(hideDropdownOptions)}
                >
                  <BuIcon name={BoostUpIcons.Link} />
                  <span>Copy link</span>
                </BuDropdownItemContainer>
              </div>
            )}
          </BuDropdown>
        </div>
      )}
    </div>
  );
};

export default HeaderDetails;
