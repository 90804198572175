import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import classNames from 'classnames';
import BuIcon from 'components/UI/BuIcon';
import { SPEAKER_SENTIMENT_ICONS } from '../CallDetails/helpers';

import * as styles from './styles';
import tableStyles from 'components/new-library/Table/styles';

import { Link } from 'react-router-dom';
import { openModal } from 'navigation/utils';
import UserAvatar from 'react-user-avatar';
import { avatarColors } from 'components/settings/styles';
import Tooltip from 'components/UI/common/Tooltip';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { Attendee, Organizer } from 'api/Calls';
import moment from 'moment';
import CallInvite from '../CallDetails/HeaderDetails/CallInvite';
import { Attendee as CallAttendee } from '../CallDetails/types';

const NotFound = () => (
  <div className={styles.cell}>
    <div className={styles.notFound}>-</div>
  </div>
);

const columnName = {
  id: 'name',
  label: 'Call Name',
  field: 'summary',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  maxWidth: 300,
  height: 90,
  config: {
    renderer({ row }: { row: IRow }) {
      const { summary, id, start, organizer, attendees, description } = row;
      const organizerValue = organizer as Organizer | undefined;

      const formattedStart = moment(`${start}`)
        .local()
        .format('dd, DD MMM YYYY, H:mm');

      const callInvite = (
        <CallInvite
          attendees={attendees as CallAttendee[]}
          description={description as string}
          callStartDate={formattedStart}
        />
      );

      return (
        <div className={styles.cell}>
          <div className={styles.cellColumn}>
            <div className={classNames(styles.cellText, styles.clickable)}>
              <Link to={`/transcript/${id}`}>{summary}</Link>
            </div>

            <Tooltip
              mouseEnterDelay={500}
              className={classNames(
                styles.whiteBgTooltip,
                styles.callInviteTooltip
              )}
              tooltip={callInvite}
            >
              <div className={styles.cellSubtext}>{formattedStart}</div>
            </Tooltip>

            <div
              className={classNames(styles.cellSubtext, 'call-organizer')}
              title={`${organizerValue?.email}`}
            >
              by {organizerValue?.display_name || organizerValue?.email}
            </div>
          </div>
        </div>
      );
    },
  } as CustomCellConfig,
};

const columnSentiment = {
  id: 'sentiment',
  label: 'Sentiment',
  field: 'sentiment',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  width: 100,
  config: {
    renderer({ row }: { row: IRow }) {
      const sentiment: string = getFieldValue('sentiment', row);

      if (
        !sentiment ||
        !['positive', 'negative', 'neutral'].includes(sentiment)
      ) {
        return <NotFound />;
      }

      return (
        <div className={styles.cell}>
          <div className={classNames(styles.sentimentBadge, sentiment)}>
            <BuIcon
              name={
                SPEAKER_SENTIMENT_ICONS[
                  sentiment as 'positive' | 'negative' | 'neutral'
                ]
              }
            />

            <span className="sentiment-badge-text">{sentiment}</span>
          </div>
        </div>
      );
    },
  } as CustomCellConfig,
};

const columnOpportunity = {
  id: 'opportunity',
  label: 'Deal Name',
  field: 'opportunity',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  width: 250,
  height: 90,
  config: {
    renderer({ row }: { row: IRow }) {
      const { opportunity_name, deal_id, deal_owner, deal_stage } = row;

      const handleClick = () => {
        openModal({
          scheme: '/deal/:id',
          params: { id: `${deal_id}` },
        });
      };

      if (!deal_id) {
        return <NotFound />;
      }

      return (
        <div className={styles.cell}>
          <div className={styles.cellColumn}>
            <div
              className={classNames(styles.cellText, styles.clickable)}
              onClick={handleClick}
            >
              <div className={classNames(tableStyles.deal_name, 'deal_name')}>
                {opportunity_name}
              </div>
            </div>
            <div className={styles.cellSubtext}>
              {deal_owner && `Owner ${deal_owner}`}
            </div>
            {deal_stage && (
              <div className={styles.dealStage} title={`${deal_stage}`}>
                {deal_stage}
              </div>
            )}
          </div>
        </div>
      );
    },
  } as CustomCellConfig,
};

const columnOrganizer = {
  id: 'organizer',
  label: 'Organizer',
  field: 'organizer',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  width: 200,
  config: {
    renderer({ row }: { row: IRow }) {
      const { organizer } = row;
      if (!organizer) {
        return null;
      }
      const organizerValue = organizer as Organizer;

      return (
        <div className={styles.cell}>
          <div className={styles.cellRow}>
            <Tooltip tooltip={`${organizerValue?.email}`}>
              <UserAvatar
                size={32}
                name={`${
                  organizerValue?.display_name || organizerValue?.email || 'NA'
                }`.toUpperCase()}
                colors={avatarColors}
              />
            </Tooltip>
            <div className={styles.cellText}>
              {organizerValue?.display_name || organizerValue?.email}
            </div>
          </div>
        </div>
      );
    },
  } as CustomCellConfig,
};

const columnAttendees = {
  id: 'attendees',
  label: 'Attendees',
  field: 'attendees',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  width: 250,
  minWidth: 100,
  config: {
    renderer({ row }: { row: IRow }) {
      const { attendees } = row;

      if (!attendees || !Array.isArray(attendees)) {
        return null;
      }
      const maxAttendees = 6;

      const shortAttendeesList =
        attendees.length <= maxAttendees
          ? attendees
          : attendees.slice(0, maxAttendees);

      const internalAttendees = attendees.filter((a) => !a.is_customer);
      const externalAttendees = attendees.filter((a) => a.is_customer);

      const attendeeListPopup = (
        <div className={styles.attendeeListPopup}>
          {internalAttendees.length > 0 && (
            <>
              <div className={styles.attendeeListPopupSectionTitle}>
                Company attendees
              </div>
              <div className={styles.attendeeListPopupSection}>
                {internalAttendees.map((attendee) => (
                  <AttendeeRow attendee={attendee} />
                ))}
              </div>
            </>
          )}

          <div className={styles.attendeeListPopupSeparator} />

          {externalAttendees.length > 0 && (
            <>
              <div className={styles.attendeeListPopupSectionTitle}>
                Prospect attendees
              </div>
              <div className={styles.attendeeListPopupSection}>
                {externalAttendees.map((attendee) => (
                  <AttendeeRow attendee={attendee} />
                ))}
              </div>
            </>
          )}
        </div>
      );

      return (
        <Tooltip
          tooltip={attendeeListPopup}
          className={styles.whiteBgTooltip}
          mouseEnterDelay={300}
        >
          <div className={styles.cell}>
            <div className={styles.attendeesAvatars}>
              {shortAttendeesList?.map((attendee, index) => (
                <div className="avatar" style={{ zIndex: 100 - index }}>
                  <UserAvatar
                    size={32}
                    name={`${
                      attendee.display_name || attendee.email || 'NA'
                    }`.toUpperCase()}
                    colors={avatarColors}
                  />
                  <div
                    className={classNames(
                      styles.attendeeResponse,
                      attendee.response_status
                    )}
                  >
                    {attendee.response_status == 'accepted' ? (
                      <BuIcon name={BoostUpIcons.Checked} />
                    ) : attendee.response_status == 'declined' ? (
                      <span>X</span>
                    ) : (
                      <span>?</span>
                    )}
                  </div>
                </div>
              ))}
              {attendees.length > maxAttendees && (
                <div className={styles.attendeesMore}>
                  +{attendees.length - maxAttendees} more
                </div>
              )}
            </div>
          </div>
        </Tooltip>
      );
    },
  } as CustomCellConfig,
};

const columnTopics = (competitors: boolean) => ({
  id: competitors ? 'competitors' : 'topics',
  label: competitors ? 'Competitors' : 'Topics',
  field: 'topics',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  config: {
    renderer({ row }: { row: IRow }) {
      const { topics } = row;
      const maxTopics = 5;

      if (!topics || !Array.isArray(topics)) {
        return <NotFound />;
      }

      const filteredTopics = competitors
        ? topics.filter((topic) => topic.is_competitor)
        : topics.filter((topic) => !topic.is_competitor);

      if (filteredTopics.length === 0) {
        return <NotFound />;
      }

      const fullTopicsList =
        filteredTopics.length > maxTopics ? (
          <div className={styles.topicsContainer}>
            {filteredTopics.slice(maxTopics).map((topic) => (
              <div className={classNames(styles.topic, topic.sentiment)}>
                {topic.topic_name}
              </div>
            ))}
          </div>
        ) : null;

      return (
        <div className={styles.cell}>
          <div className={styles.topicsContainer}>
            {filteredTopics?.slice(0, maxTopics)?.map((topic) => (
              <div className={classNames(styles.topic, topic.sentiment)}>
                {topic.topic_name}
              </div>
            ))}
            {filteredTopics?.length > maxTopics && (
              <Tooltip
                className={styles.topicsListTooltip}
                tooltip={fullTopicsList}
              >
                <div className={styles.topic}>
                  +{filteredTopics?.length - maxTopics} more
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  } as CustomCellConfig,
});

const columnActions = {
  id: 'actions',
  label: '',
  field: 'actions',
  sort_order: SortOrder.ASCENDING,
  type: ColumnTypes.CUSTOM,
  width: 100,
  config: {
    renderer({ row }: { row: IRow }) {
      const { id, no_show, source, recording_url, status } = row;

      const action =
        status === 'scheduled' ? (
          <div className={styles.cellText}>
            <div className={classNames(styles.actionButton, styles.noShow)}>
              Scheduled
            </div>
          </div>
        ) : status === 'recorded' ? (
          <div className={styles.cellText}>
            <div className={classNames(styles.actionButton, styles.noShow)}>
              Processing
            </div>
          </div>
        ) : status === 'skipped' ? (
          <div className={styles.cellText}>
            <div className={classNames(styles.actionButton, styles.noShow)}>
              Skipped
            </div>
          </div>
        ) : (
          <div className={styles.cellText}>
            {source === 'Mindtickle' ? (
              <a href={`${recording_url}`} target="_blank">
                <div className={classNames(styles.actionButton)}>
                  <BuIcon name={BoostUpIcons.MediaPlay} />
                  Play Recording
                </div>
                Via Mindtickle
              </a>
            ) : (
              <Link to={`/transcript/${id}`}>
                <div className={classNames(styles.actionButton)}>
                  <BuIcon name={BoostUpIcons.MediaPlay} />
                  Play Recording
                </div>
              </Link>
            )}
          </div>
        );

      return (
        <div className={styles.cell}>
          {no_show ? (
            <div className={classNames(styles.actionButton, styles.noShow)}>
              No Show
            </div>
          ) : (
            action
          )}
        </div>
      );
    },
  } as CustomCellConfig,
};

export const getColumns = (upcoming: boolean): IColumn[] => {
  return upcoming
    ? [columnName, columnOpportunity, columnAttendees, columnOrganizer]
    : [
        columnName,
        columnOpportunity,
        columnAttendees,
        columnSentiment,
        columnTopics(false),
        columnTopics(true),
        columnActions,
      ];
};

const AttendeeRow = ({ attendee }: { attendee: Attendee }) => (
  <div className={styles.attendeeRow}>
    <div>
      <UserAvatar
        size={32}
        name={`${
          attendee.display_name || attendee.email || 'NA'
        }`.toUpperCase()}
        colors={avatarColors}
      />
      <div
        className={classNames(
          styles.attendeeResponse,
          attendee.response_status
        )}
      >
        {attendee.response_status == 'accepted' ? (
          <BuIcon name={BoostUpIcons.Checked} />
        ) : attendee.response_status == 'declined' ? (
          <span>X</span>
        ) : (
          <span>?</span>
        )}
      </div>
    </div>
    <div className={styles.attendeeInfo}>
      <div className={styles.attendeeName} title={attendee.display_name}>
        {attendee.display_name}
      </div>
      <div className={styles.attendeeEmail} title={attendee.email}>
        {attendee.email}
      </div>
    </div>
  </div>
);
