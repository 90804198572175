import { fontSubheading1 } from 'assets/css/fontStyles';
import { css } from 'emotion';

export const wrapper = css`
  height: 100%;

  .bu-search {
    width: 300px;
  }

  .deal_name:hover {
    color: var(--bu-primary-500) !important;
  }
`;

export const filters = css`
  padding: 0 20px;
`;

export const loader = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const callsListing = css`
  height: calc(100% - 55px);
`;

export const notFound = css`
  color: var(--bu-gray-400);
  text-align: center;
`;

export const cell = css`
  padding: 10px 0;
`;

export const cellRow = css`
  display: flex;
  flex-direction: row;
  gap: var(--bu-control-gap-medium);
  align-items: center;
`;

export const cellColumn = css`
  display: flex;
  flex-direction: column;
`;

export const sentimentBadge = css`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  gap: var(--bu-control-gap-small);
  border-radius: var(--bu-control-border-radius);
  text-transform: uppercase;
  width: fit-content;
  justify-self: center;

  i {
    font-size: 14px;
  }

  .sentiment-badge-text {
    ${fontSubheading1}
    font-weight: 500;
    font-size: 12px;
  }

  &.positive {
    background-color: var(
      --bu-color-call-summary-sentiment-positive-background
    );
    color: var(--bu-green-500);
  }

  &.negative {
    background-color: var(
      --bu-color-call-summary-sentiment-negative-background
    );
    color: var(--bu-red-400);
  }
`;

export const clickable = css`
  cursor: pointer;
  margin-bottom: 2px;
`;

export const cellText = css`
  font-size: 1em !important;
  line-height: 19px;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: var(--bu-clickable-cell) !important;

    &:hover {
      color: var(--bu-primary-500) !important;
    }
  }
`;

export const cellSubtext = css`
  color: var(--bu-gray-900);
  font-size: 12px;
  line-height: 19px;
  font-weight: 300;

  &.call-organizer {
    margin-top: 2px;
    height: 22px;
  }
`;

export const dealStage = css`
  background-color: var(--bu-color-call-video-control);
  color: var(--bu-gray-900);
  padding: 2px 4px;
  border-radius: var(--bu-control-border-radius);
  font-size: 12px;
  margin-top: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
`;

export const attendeeResponse = css`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-top: -10px;
  margin-left: 20px;
  font-size: 11px;
  text-align: center;
  background-color: var(--bu-gray-400);

  &.accepted {
    color: var(--bu-green-500);
    background-color: var(--bu-green-300);
  }

  &.declined {
    color: var(--bu-red-400);
    background-color: var(--bu-red-300);
  }
`;

export const attendeesAvatars = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;

  .avatar {
    margin-left: -10px;
    position: relative;
  }

  .UserAvatar--inner {
    border: 2px solid white;
  }
`;

export const topicsContainer = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--bu-control-gap-small);
`;

export const topicsListTooltip = css`
  &.ui.inverted.popup {
    background-color: white !important;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
  }
`;

export const topic = css`
  padding: 2px 4px;
  border-radius: var(--bu-control-border-radius);
  font-size: 14px;
  width: fit-content;
  color: var(--bu-gray-900);

  &.positive {
    background-color: var(
      --bu-color-call-summary-sentiment-positive-background
    );
    color: var(--bu-green-500);
  }

  &.negative {
    background-color: var(
      --bu-color-call-summary-sentiment-negative-background
    );
    color: var(--bu-red-400);
  }
`;

export const actionButton = css`
  color: var(--bu-primary-500);
  border: 1px solid var(--bu-primary-500);
  border-radius: var(--bu-control-border-radius);
  padding: 8px 4px;
  font-size: 14px;
  width: fit-content;

  .bu-icon {
    margin-right: 4px;
  }
`;

export const noShow = css`
  opacity: 0.5;
`;

export const whiteBgTooltip = css`
  &.ui.inverted.popup {
    background-color: white !important;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
    width: 250px;
  }
`;

export const callInviteTooltip = css`
  &.ui.inverted.popup {
    margin-left: 10px;
  }
`;

export const attendeeListPopup = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);
  background-color: white;
  color: var(--bu-gray-900);
  border-radius: var(--bu-control-border-radius);
  padding: 10px 0;
`;

export const attendeeListPopupSectionTitle = css`
  font-size: 12px;
  font-weight: 600;
  padding: 5px 0;
`;

export const attendeeListPopupSection = css`
  max-height: 200px;
  overflow-y: auto;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-medium);
`;

export const attendeesMore = css`
  color: var(--bu-gray-700);
  font-size: 12px;
  align-self: flex-end;
  padding-left: 5px;
`;

export const attendeeRow = css`
  display: flex;
  flex-direction: row;
  gap: var(--bu-control-gap-medium);
`;

export const attendeeInfo = css`
  display: flex;
  flex-direction: column;
  gap: var(--bu-control-gap-small);
`;

export const attendeeName = css`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const attendeeEmail = css`
  color: var(--bu-text-gray);
  font-weight: 400;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const attendeeListPopupSeparator = css`
  border-bottom: 1px solid var(--bu-gray-300);
`;
