import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { Loader, Tab } from 'semantic-ui-react';

import { getCallInsightsExternalData } from 'api/CallInsights';
import Tooltip from 'components/UI/common/Tooltip';

import * as styles from './styles';
import { VideoProvider } from '../CallDetails/VideoPlayer/videoPlayer.context';
import HeaderDetails from '../CallDetails/HeaderDetails';
import VideoPlayer from '../CallDetails/VideoPlayer';
import CallTimeline from '../CallDetails/CallTimeline';
import { TranscriptProvider } from '../CallDetails/Tabs/Transcript/transcript.context';
import { SummaryTab, TranscriptTab } from '../CallDetails/Tabs';
import { extendCallsInsightDataObject } from '../CallDetails/helpers';

type Props = RouteComponentProps<{
  id: string;
}>;

const ExternalCallDetails: React.FC<Props> = ({ match }) => {
  const {
    isFetching: isCallInsightsExternalFetching,
    data: callInsightsExternalData,
    error: callInsightsExternalError,
  } = useQuery({
    queryKey: ['get_call_insights_external_data', match.params.id],
    retry: false,
    queryFn: async () =>
      extendCallsInsightDataObject(
        await getCallInsightsExternalData({ sharedCallId: match.params.id })
      ),
  });

  return (
    <VideoProvider>
      <main className={styles.containerWrapper}>
        {!isCallInsightsExternalFetching && !!callInsightsExternalData && (
          <>
            <header className={styles.header}>
              <div className="header-info-wrapper">
                <Tooltip
                  tooltip={callInsightsExternalData.title}
                  position="bottom left"
                >
                  <div className="call-name">
                    {callInsightsExternalData.title}
                  </div>
                </Tooltip>

                <HeaderDetails
                  attendees={callInsightsExternalData.attendees}
                  participants={callInsightsExternalData.meeting_participants}
                  speakerContactMap={
                    callInsightsExternalData.speaker_contact_map
                  }
                  title={callInsightsExternalData.title}
                  duration={callInsightsExternalData.duration}
                  start={callInsightsExternalData.start}
                  description=""
                  speakerPercentages={
                    callInsightsExternalData.speaker_percentages
                  }
                  isCallSummarized={callInsightsExternalData.is_call_summarized}
                  hideInviteTooltip
                  hideAttendeesTooltip={
                    !callInsightsExternalData.is_call_summarized
                  }
                />
              </div>
            </header>

            <section
              className={classNames(styles.content, {
                error: callInsightsExternalError,
              })}
            >
              <div>
                <VideoPlayer
                  isCallSummarized={callInsightsExternalData.is_call_summarized}
                  videoUrl={callInsightsExternalData.media_url}
                />

                <CallTimeline
                  participants={callInsightsExternalData.meeting_participants}
                  segments={callInsightsExternalData.segments}
                  duration={callInsightsExternalData.duration}
                  isCallSummarized={callInsightsExternalData.is_call_summarized}
                />
              </div>

              <div className={styles.callSummaryInsightActionItemsTranscript}>
                <TranscriptProvider>
                  <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={[
                      {
                        menuItem: 'Summary',
                        render: () => (
                          <SummaryTab
                            summary={callInsightsExternalData.call_summary_data}
                            hideOpportunityAndAE
                            hideSentiment
                          />
                        ),
                      },
                      {
                        menuItem: 'Transcript',
                        render: () => (
                          <TranscriptTab
                            segments={callInsightsExternalData.segments}
                            participants={
                              callInsightsExternalData.meeting_participants
                            }
                          />
                        ),
                      },
                    ]}
                  />
                </TranscriptProvider>
              </div>
            </section>
          </>
        )}

        {isCallInsightsExternalFetching && <Loader active />}

        {callInsightsExternalError && (
          <div className={styles.requestErrorWrapper}>
            <h1>ERROR!</h1>

            <span>
              We were unable to retrieve the requested data. Please try again
              later.
            </span>
          </div>
        )}
      </main>
    </VideoProvider>
  );
};

export default ExternalCallDetails;
